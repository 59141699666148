import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const Ledger = Loadable(lazy(() => import('./Ledger')));


const accRoutes = [
  { path: '/ledger', element: <Ledger /> },
];

export default accRoutes;
